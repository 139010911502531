import Link from 'next/link';
import type React from 'react';
import styled from 'styled-components';
import { type ButtonVariants, getButtonStyling } from '../Button/styling';

const StyledLink = styled(Link)<{
  $variant: ButtonVariants;
  $fullWidth?: boolean;
}>`
  ${({ $variant, $fullWidth }) => getButtonStyling({ $variant, $fullWidth })}
`;

type Props = {
  children: React.ReactNode;
  href: string;
  variant: ButtonVariants;
  fullWidth?: boolean;
  className?: string;
  target?: string;
  id?: string;
  prefetch?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const LinkButton = ({
  id,
  children,
  href,
  variant,
  fullWidth,
  className,
  target,
  prefetch,
  onClick,
}: Props) => (
  <StyledLink
    id={id}
    href={href}
    $variant={variant}
    $fullWidth={fullWidth}
    className={className}
    target={target}
    onClick={onClick}
    prefetch={prefetch || false}
  >
    {children}
  </StyledLink>
);

export default LinkButton;
