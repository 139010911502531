const fixedLocalUrl = (url: string) => {
  try {
    const urlParsed = new URL(url);
    if (urlParsed.port && urlParsed.hostname === 'localhost') {
      urlParsed.port = '4201';
      return urlParsed.toString();
    }
  } catch (e) {
    //
  }
  return url;
};

export const formatUrl = (pathOrUrl?: string | null) => {
  if (!pathOrUrl) return '#';

  pathOrUrl = fixedLocalUrl(pathOrUrl);
  if (pathOrUrl.endsWith('/')) {
    return pathOrUrl;
  }
  // For anchor link
  if (pathOrUrl.includes('/#')) {
    return pathOrUrl;
  }
  return `${pathOrUrl}/`;
};
