import { css } from 'styled-components';

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'text'
  | 'purple'
  | 'transparentWhite'
  | 'transparentPrimary'
  | 'transparentPurple'
  | 'white';

// For background position and size, extra pixels are needed for the background to not have any gaps due to rounded pixels.
const hoverStyling = (mainColor: string, hoverColor: string) => css`
  background: linear-gradient(to left, ${mainColor} 50%, ${hoverColor} 50%) calc(100% + 1px);
  background-size: calc(200% + 3px);
  transition: 0.25s ease-out;

  &:hover {
    color: var(--color-white);
    border-color: ${hoverColor};
  }
`;

const primaryStyling = css`
  color: var(--color-white);
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
  ${hoverStyling('var(--color-primary)', 'var(--button-color-hover)')}
`;

const secondaryStyling = css`
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  background: var(--color-white);
  ${hoverStyling('var(--color-white)', 'var(--button-color-hover)')}
`;

const textStyling = css`
  color: var(--color-black);
  background: transparent;
  transition: color 0.25s ease;

  &:hover {
    color: var(--color-primary);
  }
`;

const transparentWhiteStyling = css`
  color: var(--color-white);
  border: 2px solid var(--color-white);
  background: transparent;
  transition: color 0.25s ease;
  ${hoverStyling('transparent', 'var(--color-white)')}

  &:hover {
    color: var(--color-primary);
  }
`;

const transparentPrimaryStyling = css`
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  background: transparent;
  ${hoverStyling('transparent', 'var(--button-color-hover)')}
`;

const transparentPurpleStyling = css`
  color: var(--color-purple);
  border: 2px solid var(--color-purple);
  background: transparent;
  ${hoverStyling('transparent', 'var(--purple-button-color-hover)')}
`;

const purpleStyling = css`
  color: var(--color-white);
  background: var(--color-purple);
  border: 2px solid var(--color-purple);
  ${hoverStyling('var(--color-purple)', 'var(--purple-button-color-hover)')}
`;

const whiteStyling = css`
  color: var(--color-primary);
  border: 2px solid var(--color-white);
  background: var(--color-white);
  ${hoverStyling('var(--color-white)', '#f2f2f2')}

  &:hover {
    color: var(--color-primary);
  }
`;
export const getButtonStyling = ({
  $variant,
  $fullWidth,
  disabled,
}: {
  $variant: ButtonVariants;
  $fullWidth?: boolean;
  disabled?: boolean;
}) => {
  let variantStyling;
  switch ($variant) {
    case 'primary':
      variantStyling = primaryStyling;
      break;
    case 'secondary':
      variantStyling = secondaryStyling;
      break;
    case 'text':
      variantStyling = textStyling;
      break;
    case 'purple':
      variantStyling = purpleStyling;
      break;
    case 'transparentWhite':
      variantStyling = transparentWhiteStyling;
      break;
    case 'transparentPrimary':
      variantStyling = transparentPrimaryStyling;
      break;
    case 'transparentPurple':
      variantStyling = transparentPurpleStyling;
      break;
    case 'white':
      variantStyling = whiteStyling;
      break;
    default:
      variantStyling = primaryStyling;
      break;
  }

  return css`
    text-decoration: none;
    font-size: var(--button-font-size);
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--button-padding-y) var(--button-padding-x);
    width: ${$fullWidth ? '100%' : 'auto'};
    cursor: pointer;
    ${variantStyling};

    &:hover {
      background-position: left;
    }

    ${
      disabled &&
      css`
      cursor: not-allowed;
      background: var(--button-color-hover);
    `
    }
  `;
};
