import { GREY_300 } from '@mnd-frontend/ui';
import Link from 'next/link';
import styled from 'styled-components';

export const PostTag = styled(Link)<{ $background?: string }>`
  position: relative;
  display: inline-block;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  font-size: var(--font-size-x-small);
  padding: 3px 8px;
  border-radius: 2rem;
  line-height: 1.2;
  overflow: hidden;
  color: var(--color-white);
  z-index: 1;
  opacity: 0.8;
  transition: opacity var(--anim-speed) var(--easing);

  &:hover {
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ $background }) =>
      $background === 'transparent' ? 'var(--color-white)' : ($background ?? GREY_300)};
    filter: brightness(0.55);
    z-index: -1;
  }
`;
