import styled from 'styled-components';
import type { ImageFragment } from '../../wp-generated/types';

type ObjectFit = 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
type AspectRatio = { mobile: string; desktop: string };

const ImgWrapper = styled.div<{ $aspectratio: AspectRatio }>`
  width: 100%;
  aspect-ratio: ${({ $aspectratio }) => $aspectratio.mobile};
  @media (min-width: 1080px) {
    aspect-ratio: ${({ $aspectratio }) => $aspectratio.desktop};
  }
  overflow: hidden;
`;

const Img = styled.img<{ $objectfit: ObjectFit }>`
  width: 100%;
  height: 100%;
  object-fit: ${({ $objectfit }) => $objectfit};
  transition: transform 0.3s ease-in-out;
  will-change: transform;
  &:hover {
    transform: scale(1.2);
  }
`;

interface PostImageProps {
  data?: ImageFragment;
  aspectRatio?: AspectRatio;
  objectFit?: ObjectFit;
}

const PostImage = ({
  data,
  aspectRatio = { mobile: '16 / 9', desktop: '16 / 9' },
  objectFit = 'cover',
}: PostImageProps) => {
  if (!data || !data?.mediaItemUrl) return null;

  return (
    <ImgWrapper $aspectratio={aspectRatio}>
      <Img
        $objectfit={objectFit}
        src={`${data?.mediaItemUrl}`}
        alt=""
        role="presentation"
        srcSet={data?.srcSet || undefined}
        sizes={data?.sizes || undefined}
        loading="lazy"
        title={data?.altText || undefined}
      />
    </ImgWrapper>
  );
};

export default PostImage;
